<template>
    <BLink v-bind="$props" v-on="$listeners" :class="getVariant()">
        <i v-if="hasIcon" class="fal fa-sm" :class="icon" />
        <span v-if="hasContent" class="ml-1">
            <slot />
        </span>
    </BLink>
</template>

<script lang="ts">
import { BLink } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { BLink } })
export default class Link extends Vue {
    @Prop({ required: false, default: null }) public icon: string;
    @Prop({ required: false, default: false }) public disabled: string;
    @Prop({ required: false, default: '_self' }) public target: string;
    @Prop({ required: false }) public href: string;
    @Prop({ required: false, default: null }) public variant: string;

    get hasContent(): boolean {
        return !!this.$slots.default;
    }

    get hasIcon(): string {
        return this.icon;
    }

    public getVariant(): string[] {
        if (this.variant) {
            return ['btn', `btn-${this.variant}`];
        }
        return [];
    }
}
</script>
