import Button from '@/app/Common/Components/Button.vue';
import Link from '@/app/Common/Components/Link.vue';
import CustomStyler from '@/app/Config/CustomStyler';
import InstanceService from '@/app/Instance/Services/InstanceService';
import i18n from '@/app/Langs/I18nConfig';
import UserService from '@/app/User/Services/UserService';
import '@/assets/css/styles.scss';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import ServiceInitializer from './app/Common/Services/ServicesInitializer';
import router from './router';

import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

export const createApp = () => {
    Vue.use(BootstrapVue);
    Vue.use(VueI18n);
    Vue.config.productionTip = false;

    const [instanceService, userService, serviceInitializer, customStylor] = [
        new InstanceService(),
        new UserService(),
        new ServiceInitializer(),
        new CustomStyler(),
    ];
    const instance = instanceService.getInstanceFromCookie();
    const user = userService.getUserDataFromCookie(instance.slug);
    const services = serviceInitializer.getServices(instance.token, user.token);

    customStylor.mountStyle(instance.mainColor);

    const app = new Vue({
        render: h => h(App),
        router,
        i18n,
        provide: {
            instance,
            user,
            customStylor,
            ...services,
        },
    }).$mount('#app');

    Vue.component('b-button', Button);
    Vue.component('b-link', Link);

    return app;
};
