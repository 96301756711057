import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { defaultLocale, languages } from '@/app/Common/Services/LanguageService';

Vue.use(VueI18n);

const messages = Object.assign(languages);
const i18n = new VueI18n({
    locale: defaultLocale,
    messages,
    silentTranslationWarn: true,
});

export default i18n;
