import Language from './Language';

const en: Language = {
    BASE_UPLOAD: 'Import base',
    BASE_VALIDATION: 'Base validation',
    BASE_ADJUSTMENT: 'Base adjustment',
    BASE_SENT: 'Base sent',
    BASE_IMPORTER_TITLE: 'Base importer',
    UPLOAD_BASE_BLOCK_SUBTITLE:
        'To upload your database, you must first verify that the file is ready to be sent. Follow the instructions below to make sure that your database follows all the necessary rules for a successful upload.',
    INSTRUCTIONS_BLOCK_TITLE: 'Structure and pattern of the database',
    INSTRUCTIONS_BLOCK_SUBTITLE: 'To import your experiences, it is necessary to fulfill the following conditions:',
    IMPORT_INSTRUCTIONS: `
        <li class="text-muted">The file must be in CSV format.</li>
        <li class="text-muted">It must have a maximum size of 30 megabytes.</li>
        <li class="text-muted">The column titles must exactly follow the template pattern.</li>
        <li class="text-muted">Rows with mandatory information left blank will not be recorded.</li>
        <li class="text-muted">The columns <strong>id_cliente</strong>, <strong>nome_do_cliente</strong>, <strong>email</strong> or <strong>telefone</strong>, <strong>id_transacao</strong>, <strong>id_loja</strong>, <strong>data_transacao</strong>, and <strong>jornada</strong> are considered mandatory, therefore they need to be filled in.</li>
        <li class="text-muted">It is mandatory to have the contact information related to the configured channel of delivery. For example, if the journey is set to dispatch an email, this data must be in the database.</li>
        <li class="text-muted">Filling in the non-mandatory columns ensures a good experience using the platform.</li>
        <li class="text-muted">Phone numbers need to follow the format DDD + Phone number. Example: (12) 999999999.</li>
        <li class="text-muted">The <strong>id_cliente</strong> is the code that will be used to find the customer on the platform, and it is possible to add <strong>CPF</strong>, <strong>email</strong> or any other data that assists in the search.</li>
        <li class="text-muted">Information must be separated into 3 categories: <strong>mandatory</strong>, <strong>important</strong>, and <strong>extra parameters</strong>.</li>
        <ul class="text-muted m-0">
            <li>Mandatory information includes <strong>id_cliente</strong>, <strong>nome_do_cliente</strong>, <strong>email</strong> or <strong>telefone</strong>, <strong>id_transacao</strong>, <strong>id_loja</strong>, <strong>data_transacao</strong>, and <strong>jornada</strong>.</li>
            <li>Important information includes <strong>id_consultor</strong>, <strong>sexo_cliente</strong>, <strong>telefone</strong>, <strong>cpf</strong>, <strong>valor</strong>, <strong>nome_do_vendedor</strong>.</li>
            <li>The other columns will be considered as extra parameters.</li>
        </ul>
        <li class="text-muted">If the structure is correctly followed, your database will pass the validation process. However, this does not guarantee that all experiences it comprises will be successfully triggered.</li>
    `,
    DOWNLOAD_TEMPLATE_BUTTON: 'Download database template',
    INVALID_EXTENSION_ERROR: 'Invalid file extension',
    FILESIZE_LIMIT: 'Exceeded file size limit',
    NO_FILE: 'Unable to read file, please try again',
    UPLOAD_SELECTION_INSTRUCTIONS: 'Drag and drop or <strong>pick</strong> a file in csv format',
    FILESIZE_LIMIT_INSTRUCTION: 'Maximum size of 30MB',
    CANCEL: 'Cancel',
    LOCALLY_PROGRESS_MESSAGE: 'Upload the file according to the CSV standard.',
    IMPORT_AND_DISPATCH_BUTTON: 'Upload and dispatch',
    FILE_UPLOADING_PROGRESS: 'Validation of the base and its records...',
    FILE_UPLOADING_ERROR_DETECTED: 'Error detected during base uploading',
    API_UPLOAD_ERROR_MESSAGE: 'Check the conditions set in the default base structure and try again',
    DISPATCH_BEING_PREPARED_TITLE: 'Base successfully dispatched!',
    DISPATCH_BEING_PREPARED_MESSAGE: `
    The adjustments made to the database will be applied, and then the database will be directed to the firing queue. It is possible to track the <br /> status changes of your database through the <strong>Notification Center</strong> and the Fired Bases page.
    `,
    DISPATCH_ERROR_TITLE: 'An error has occurred in the base processing!',
    DISPATCH_ERROR_MESSAGE: `
        While processing your base an error occurred, try importing again. <br />If in doubt, contact the SoluCX team for assistance in correcting the base.
    `,
    DISPATCHING_TITLE: 'Base being dispatched.',
    DISPATCHING_MESSAGE: `
        Your experience base is being fired, this operation may take a while. <br />You can access the <a href="{extractUrl}">General Extract</a> and keep track of the shots already taken.
    `,
    DISPATCH_QUEUE_TITLE: 'Your base is in the dispatching queue.',
    DISPATCH_QUEUE_MESSAGE: `
        Your experience base is currently in the queue to be dispatched, so please wait a few minutes. <br />In moments your experiences will be dispatched.
    `,
    VALIDATING_BASE_TITLE: 'Your base is being validated',
    VALIDATING_BASE_MESSAGE: 'Wait a few minutes while your base information is processed.',
    IMPORT_AND_VALIDATE_BASE_BUTTON: 'Import and validate base',
    INFORMATION_SUMMARY: 'Information summary',
    DATE_LABEL: 'Dates',
    STORE_LABEL: 'Stores',
    JOURNEY_LABEL: 'Journeys',
    TOTAL_RECORDS: 'Total records',
    INCLUDED_IN_THE_BASE: 'included in the base',
    DATE_VALIDATION_ERROR_TITLE: 'Incorrect date formatting in the base',
    DATE_VALIDATION_ERROR_MESSAGE:
        'Dates with invalid formatting were found in your database. <br /> Update the date information to YYYY-MM-DD or YYYY-MM-DD HH:mm:ss format and perform the import again.',
    HEADER_VALIDATION_ERROR_TITLE: 'Error during column identification!',
    HEADER_VALIDATION_ERROR_MESSAGE:
        'During the validation of your base it was not possible to identify the columns. Make sure that the column names follow the indicated pattern. <br />If in doubt, contact the SoluCX team for assistance in correcting the base.',
    AMOUNT: 'Amount',
    DESCRIPTION: 'Description',
    CLASSIFICATION: 'Classification',
    ACTION: 'Action',
    SELECT_ACTION: 'Select an action',
    SELECT_ACTION_FOR_THE_ERROR: 'Select an action for each error below',
    ERROR: 'Error',
    WARNING: 'Alert',
    REMOVE_LINE: 'Remove line',
    IGNORE_LINE: 'Ignore line',
    REMOVE_INFO: 'Remove info',
    FIX_INFO: 'Fix info',
    AMOUNT_OF_ERRORS_FOUND: 'Total amount of errors found',
    IS_TRANSACTION_EXTERNAL_ID_WELL_FORMATTED: 'Invalid transaction id',
    IS_TRANSACTION_EXTERNAL_ID_NOT_REPEATED: 'Duplicate transaction id',
    IS_STORE_EXISTENT: 'Store id not registered',
    IS_STORE_NOT_EMPTY: 'Invalid store - empty',
    IS_JOURNEY_EXISTENT: 'Journey unidentified',
    IS_CUSTOMER_EXTERNAL_ID_NOT_REPEATED: 'Duplicate customer id',
    IS_EXPERIENCE_DATE_WELL_FORMATTED: 'Invalid date format',
    IS_EXPERIENCE_DATE_NOT_FROM_FUTURE: 'Future date invalid',
    IS_EMAIL_NOT_REPEATED: 'Duplicate e-mail in the base',
    IS_EMAIL_WELL_FORMATTED: 'Invalid e-mail - formatting',
    IS_EMAIL_NOT_BLACKLISTED: 'Invalid e-mail - blacklist',
    IS_EMAIL_DOMAIN_NOT_MISTAKEN: 'Invalid e-mail - misspelled',
    IS_EMAIL_NOT_SUSPECTED: 'Suspicious e-mails',
    IS_EMAIL_NOT_EMPTY: 'Invalid e-mail - empty',
    IS_EMAIL_GMAIL_COMPLIANT: 'Invalid e-mail - gmail rules',
    IS_EMAIL_GENERAL_DOMAIN_COMPLIANT: 'Invalid e-mail - general rules',
    IS_MAIN_PHONE_WELL_FORMATTED: 'Invalid phone format - non-standard',
    IS_MAIN_PHONE_VALID_BRAZILIAN_DDD: 'Invalid phone number - unknown area code',
    IS_ADDITIONAL_PHONE_WELL_FORMATTED: 'Invalid additional phone format - not standard',
    IS_ADDITIONAL_PHONE_VALID_BRAZILIAN_DDD: 'Invalid additional phone number - unknown area code',
    IS_DOCUMENT_LENGTH_VALID: 'Character limit',
    IS_CUSTOMER_NAME_NOT_EMPTY: 'Customer name empty',
    IS_STORE_ACTIVE: 'Inactive store',
    ADJUST_AND_DISPATCH_BASE: 'Adjust and dispatch base',
    ERROR_CALLING_CLEAN_BASE_TITLE: 'Unexpected error',
    ERROR_CALLING_CLEAN_BASE_MESSAGE: 'An error occurred while trying to clean the base. Please try again later.',
    ERROR_ACTION_IS_EMPTY_TITLE: 'Select an action for all items listed in the table.',
    ERROR_ACTION_IS_EMPTY_MESSAGE: 'There are still uncorrected errors!',
    APPLYING_ADJUSTS_TITLE: 'Adjustments are being applied.',
    APPLYING_ADJUSTS_MESSAGE: 'The selected actions are being applied to your database. Please wait a few minutes.',
    NO_RESULTS: 'No errors found in your base',
    ERROR_CALLING_DELETE_BASE_TITLE: 'Error when trying to remove the base',
    ERROR_CALLING_DELETE_BASE_MESSAGE: 'An error occurred while trying to remove the base. Please try again later.',
    POPUP_CANCEL_DISPATCH_TITLE: 'Stop dispatch',
    YES_CANCEL_THE_DISPATCH: 'Yes, cancel the dispatch',
    POPUP_CANCEL_DISPATCH_MESSAGE: 'Are you sure you would like to cancel the dispatch? By confirming this action, all progress made will be discarded.',
    POPUP_DISPATCH_TITLE: 'Dispatch base',
    POPUP_DISPATCH_MESSAGE: 'Are you sure you would like to dispatch the base? By confirming this action, it will not be possible to cancel the dispatch.',
    CLOSE: 'Close',
    GO_TO_EXTRACT: 'Go to extract',
    DOWNLOAD_ERRORS: 'Download errors',
    BASES_TITLE: 'Dispatch bases',
    NO_IMPORTED_BASES: 'There was an error retrieving the databases.',
    ERROR_RETRIEVING_BASES: 'No databases imported.',
    RECORDS: 'Records',
    DISPATCHES: 'Dispatches',
    NON_DISPATCHES: 'Not dispatches',
    CREATED: 'Created',
    UPLOADED: 'Uploaded',
    VALIDATING: 'Validating',
    VALIDATION_ERROR: 'Validation error',
    EMPTY_HEADER_FIELD_ERROR: 'Empty header',
    TRAILING_DATA_ERROR: 'Trailing data',
    MISSING_REQUIRED_FIELDS_ERROR: 'Missing required fields',
    DATE_VALIDATION_ERROR: 'Date error',
    VALIDATED: 'Validated',
    ADJUSTING_QUEUE: 'Adjusting queue',
    ADJUSTING_BASE: 'Adjusting base',
    ADJUSTED_BASE: 'Base adjusted',
    DISPATCHING: 'Dispatching',
    DISPATCHED_ALL: 'Dispatched',
    DISPATCHED_INCOMPLETE: 'Dispatched',
    DISPATCH_ERROR: 'Dispatch error',
    UNKNOWN: 'Unknown',
    BASE_NAME: 'Base name',
    STATUS: 'Status',
    DISPATCH_START_AT: 'Start of dispatch',
    DISPATCH_ENDED_AT: 'End of dispatch',
    DOWNLOADING_FILE_ERROR: 'There was an error trying to download the file',
    SELECT_A_BASE: 'Select an imported base to view its information.',
    THERE_WAS_ERROR_RETRIEVING_BASE_DATA: 'There was an error retrieving the records data from the base',
    THERE_WAS_ERROR_RETRIEVING_NON_DISPATCH_REASONS: 'There was an error retrieving the reasons for non dispatch from the base',
    EMAILSERVICE_INVALID_EMAIL: 'Invalid email service configuration',
    EMAILSERVICE_BOUNCED_EMAIL: 'Email service rejected the message',
    CALCULATOR_NO_DAILY_BUDGET: 'Unit without a daily budget for sending',
    CALCULATOR_DAILY_BUDGET_EXCEEDED: 'Exceeded the units daily budget',
    SURVEY_BLOCKED: 'Exclusion list',
    CUSTOMER_EMAIL_BLACKLIST: 'Domain on the blacklist',
    DOUBLE_IN_DAY_DISPATCH: 'Contact already sent on the day',
    CUSTOMER_INVALID_EMAIL: 'Invalid email',
    CUSTOMER_INVALID_PHONE: 'Invalid phone number',
    EMAIL_OPTOUT: 'Email opt-out',
    SMS_OPTOUT: 'SMS opt-out',
    WHATSAPP_OPTOUT: 'WhatsApp opt-out',
    URA_OPTOUT: 'Phone call opt-out',
    QUARANTINE_TRANSACTION: 'Send quarantine',
    QUARANTINE_RATING: 'Evaluation quarantine',
    STORE_CANNOT_SEND_EMAIL: 'Unit blocked for sending',
    INTERNAL_ERROR: 'Temporary error',
    CONFIGURATION_ERROR: 'Journey configuration error',
    EMPTY_HEADER_FIELD_ERROR_TITLE: 'Base with empty header',
    EMPTY_HEADER_FIELD_ERROR_MESSAGE: 'Some columns in your base have empty headers. <br />Correct your base header and try importing again.',
    TRAILING_DATA_ERROR_TITLE: 'Trailing data in the base',
    TRAILING_DATA_ERROR_MESSAGE:
        'There is information in your base that does not fit into a column specified in the header. <br />Remove the information or add a header and try the import again.',
    MISSING_REQUIRED_FIELDS_ERROR_TITLE: 'Base with incomplete header',
    MISSING_REQUIRED_FIELDS_ERROR_MESSAGE:
        "Your base header doesn't have all the required fields. <br />Correct your database by adding all the required fields and try importing again.",
    API_CUSTOMER_INVALID_NAME: 'Customer name cannot be empty',
    API_CUSTOMER_PHONE_LESS_THAN_10_DIGITS: 'Phone has less than 10 digits',
    API_CUSTOMER_ADDITIONAL_PHONE_LESS_THAN_10_DIGITS: 'Additional phone has less than 10 digits',
    API_CUSTOMER_INVALID_CPF: 'Invalid CPF',
    API_CUSTOMER_INVALID_EMAIL: 'Invalid e-mail',
    API_EMPLOYEE_INVALID_NAME: "Employee's name must not be empty",
    API_STORE_INVALID_NAME: 'Store name cannot be empty',
    API_TRANSACTION_INVALID_AMOUNT: 'Transaction value must be numeric',
    TRANSACTION_ALREADY_CREATED: 'Transaction already created',
    IS_MAIN_PHONE_NOT_VALID_DOCUMENT: 'Phone number is also a valid CPF',
    IS_ADDITIONAL_PHONE_NOT_VALID_DOCUMENT: 'Additional phone number is also a valid CPF',
    JOURNEY_FOUND_BUT_NOT_ACTIVE: 'Journey not active',
    IS_JOURNEY_ACTIVE: 'Journey not active',
    IS_DOCUMENT_CONTENT_VALID: 'Invalid document',
};

export default en;
