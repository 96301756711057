<template>
    <BButton v-bind="$props" v-on="$listeners">
        <i v-if="hasIcon && !loading" class="fal fa-sm mr-1" :class="icon" />
        <b-spinner small v-if="loading"></b-spinner>
        <span v-if="hasContent">
            <slot />
        </span>
    </BButton>
</template>

<script lang="ts">
import { BButton } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { BButton } })
export default class Button extends Vue {
    @Prop({ required: false, default: null }) public icon: string;
    @Prop({ required: false, default: false }) public disabled: string;
    @Prop({ required: false, default: null }) public size: string;
    @Prop({ required: false, default: 'secondary' }) public variant: string;
    @Prop({ required: false, default: false }) public loading: boolean;

    get hasContent(): boolean {
        return !!this.$slots.default;
    }

    get hasIcon(): string {
        return this.icon;
    }
}
</script>
