import en from '@/app/Langs/English';
import es from '@/app/Langs/Spanish';
import pt from '@/app/Langs/Portuguese';
import { getCookie } from '@/app/Common/Services/CookieService';

export const defaultLocale = getInitialLanguage();

export const languages = { pt, en, es };

export function getInitialLanguage(langs: string[] = ['em', 'pt', 'en', 'es'], defaultLang: string = 'pt'): string {
    const lang = getLanguage();
    if (langs.indexOf(lang) >= 0) return lang;
    else return defaultLang;
}

function getLanguage(): string {
    const lang: string = getCookie('language') || window.navigator.language;
    return lang.substring(0, 2).toLowerCase().replace('-', '_');
}

export function getExtendedLanguage(language: string): string {
    return language === 'pt' ? 'pt-br' : language;
}

export function getLanguageFromUrl(): string {
    const urlParameters = new URLSearchParams(window.location.search);
    const lang = urlParameters.get('lang');
    return lang ?? null;
}

export function getLanguages() {
    return { en, pt, es };
}
