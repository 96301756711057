import { ValidationResultType } from './ValidationResultType';
import ValidationResultDto from './ValidationResultDto';

export default class ValidationResult {
    public frequency: number;
    public rule: string;
    public type: ValidationResultType;
    public actions: string[];

    public _hasError: boolean = false;
    public _rowVariant: string = null;

    constructor(result: ValidationResultDto) {
        this.frequency = result.frequency;
        this.rule = result.rule;
        this.type = result.type;
        this.actions = result.actions;
    }
}
