/**
 * https://solucx.atlassian.net/wiki/spaces/Autonomia/pages/2486796304/Refresh+base+end-point
 */
export enum BaseStatus {
    CREATED = 'created',

    UPLOADED = 'uploaded',
    VALIDATING = 'validating',
    VALIDATION_ERROR = 'validation_error',
    DATE_VALIDATION_ERROR = 'date_validation_error',
    EMPTY_HEADER_FIELD_ERROR = 'empty_header_field_error',
    TRAILING_DATA_ERROR = 'trailing_data_error',
    MISSING_REQUIRED_FIELDS_ERROR = 'missing_required_fields_error',

    VALIDATED = 'validated',

    ADJUSTING_QUEUE = 'adjusting_queue',
    ADJUSTING_BASE = 'adjusting_base',

    ADJUSTED_BASE = 'adjusted_base',

    DISPATCHING = 'dispatching',
    DISPATCHED_ALL = 'dispatched_all',
    DISPATCHED_INCOMPLETE = 'dispatched_incomplete',
    DISPATCH_ERROR = 'dispatch_error',

    UNKNOWN = 'unknown',
}
