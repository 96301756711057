import BaseDto from './BaseDto';
import BaseState from './BaseState';
import { BaseStatus } from './BaseStatus';

export default class Base {
    public id: string;
    public status: BaseStatus;
    public filename: string;
    public createdAt: string;
    private state: BaseState;

    constructor(base: BaseDto) {
        this.id = base.id;
        this.status = base.status;
        this.filename = base.filename;
        this.createdAt = base.createdAt;
        this.state = new BaseState(this.status);
    }

    public get isValidating(): boolean {
        return this.status === BaseStatus.UPLOADED || this.status === BaseStatus.VALIDATING;
    }

    public get isBaseAdjustment(): boolean {
        return this.status === BaseStatus.VALIDATED;
    }

    public get isBeingPreparedToDispatch(): boolean {
        return this.isAdjustingBase || this.isAwaitingDispatch || this.isDispatching || this.isDispatched;
    }

    public get isAdjustingBase(): boolean {
        return this.status === BaseStatus.ADJUSTING_QUEUE || this.status === BaseStatus.ADJUSTING_BASE;
    }

    public get isAwaitingDispatch(): boolean {
        return this.status === BaseStatus.ADJUSTED_BASE;
    }

    public get isDispatching(): boolean {
        return this.status === BaseStatus.DISPATCHING;
    }

    public get isDispatched(): boolean {
        return this.state.isDispatched;
    }

    public get hasDispatchError(): boolean {
        return this.state.hasDispatchError;
    }

    public get isStatusNotRecognized(): boolean {
        return !Object.values(BaseStatus).includes(this.status);
    }
}
