import NonDispatchBaseResultDto from '@/app/Bases/Models/NonDispatchBaseResultDto';

export default class NonDispatchBaseResult implements NonDispatchBaseResultDto {
    public id: number;
    public frequency: number;
    public reason: string;

    constructor(result: NonDispatchBaseResultDto) {
        this.id = result.id;
        this.frequency = result.frequency;
        this.reason = result.reason;
    }
}
