import Language from './Language';

const es: Language = {
    BASE_UPLOAD: 'Importar base',
    BASE_VALIDATION: 'Validación de base',
    BASE_ADJUSTMENT: 'Ajuste de la base',
    BASE_SENT: 'Base enviada',
    BASE_IMPORTER_TITLE: 'Importador de base',
    UPLOAD_BASE_BLOCK_SUBTITLE:
        'Para cargar su base de datos, primero debe verificar que el archivo esté listo para ser enviado. Siga las instrucciones a continuación para asegurarse de que su base de datos cumpla con todas las reglas necesarias para una carga exitosa.',
    INSTRUCTIONS_BLOCK_TITLE: 'Estructura y patrón de la base de datos',
    INSTRUCTIONS_BLOCK_SUBTITLE: 'Para importar sus experiencias, es necesario cumplir las siguientes condiciones:',
    IMPORT_INSTRUCTIONS: `
        <li class="text-muted">El archivo debe estar en formato CSV.</li>
        <li class="text-muted">Debe tener un tamaño máximo de 30 megabytes.</li>
        <li class="text-muted">Los títulos de las columnas deben seguir exactamente el patrón de la plantilla.</li>
        <li class="text-muted">Las filas que contienen información obligatoria en blanco no serán registradas.</li>
        <li class="text-muted">Las columnas <strong>id_cliente</strong>, <strong>nome_do_cliente</strong>, <strong>email</strong> o <strong>telefone</strong>, <strong>id_transacao</strong>, <strong>id_loja</strong>, <strong>data_transacao</strong> y <strong>jornada</strong> se consideran obligatorias, por lo tanto, deben estar completas.</li>
        <li class="text-muted">Es obligatorio tener la información de contacto correspondiente al canal de envío configurado. Por ejemplo, si la jornada está configurada para enviar un correo electrónico, esta información debe estar en la base de datos.</li>
        <li class="text-muted">Rellenar las columnas no obligatorias asegura una buena experiencia utilizando la plataforma.</li>
        <li class="text-muted">Los números de teléfono deben seguir el formato DDD + número. Ejemplo: (12) 999999999.</li>
        <li class="text-muted">El <strong>id_cliente</strong> es el código que se utilizará para encontrar al cliente en la plataforma, y es posible agregar <strong>CPF</strong>, <strong>email</strong> o cualquier otro dato que facilite la búsqueda.</li>
        <li class="text-muted">La información debe estar separada en 3 categorías: <strong>obligatorias</strong>, </strong>importantes<strong> y <strong>parámetros adicionales</strong>.</li>
        <ul class="text-muted m-0">
            <li>La información obligatoria incluye <strong>id_cliente</strong>, <strong>nome_do_cliente</strong>, <strong>email</strong> o <strong>telefone</strong>, <strong>id_transacao</strong>, <strong>id_loja</strong>, </strong>data_transacao<strong> y <strong>jornada</strong>.</li>
            <li>La información importante incluye <strong>id_consultor</strong>, <strong>sexo_cliente</strong>, <strong>telefone</strong>, <strong>cpf</strong>, <strong>valor</strong>, <strong>nome_do_vendedor</strong>.</li>
            <li>Las otras columnas se considerarán parámetros adicionales.</li>
        </ul>
        <li class="text-muted">Si se sigue correctamente la estructura, su base de datos pasará por el proceso de validación. Sin embargo, esto no garantiza que todas las experiencias que comprende se desencadenen con éxito.</li>
    `,
    DOWNLOAD_TEMPLATE_BUTTON: 'Descargar plantilla de base de datos',
    INVALID_EXTENSION_ERROR: 'Formato de archivo inválido',
    FILESIZE_LIMIT: 'Límite de tamaño de archivo excedido',
    NO_FILE: 'No se puede leer el archivo, inténtalo de nuevo',
    UPLOAD_SELECTION_INSTRUCTIONS: 'Arrastra y suelta o <strong>selecciona</strong> un archivo en formato csv',
    FILESIZE_LIMIT_INSTRUCTION: 'Tamaño máximo de 30MB',
    CANCEL: 'Cancelar',
    LOCALLY_PROGRESS_MESSAGE: 'Cargue el archivo de acuerdo con el estándar CSV.',
    IMPORT_AND_DISPATCH_BUTTON: 'Carregar e enviar',
    FILE_UPLOADING_PROGRESS: 'Validación de la base y sus registros...',
    FILE_UPLOADING_ERROR_DETECTED: 'Error detectado durante la validación de la base',
    API_UPLOAD_ERROR_MESSAGE: 'Verifique las condiciones establecidas en la estructura base predeterminada e intente nuevamente',
    DISPATCH_BEING_PREPARED_TITLE: '¡Base disparada con éxito!',
    DISPATCH_BEING_PREPARED_MESSAGE: `
    Los ajustes realizados en la base se aplicarán y luego la base se dirigirá a la cola de disparo. Es posible hacer un seguimiento de los cambios de estado de <br /> su base de datos a través del <strong>Centro de Notificaciones</strong> y la página de Bases Disparadas..
    `,
    DISPATCH_ERROR_TITLE: '¡Ha ocurrido un error al procesar la base!',
    DISPATCH_ERROR_MESSAGE: `
        Durante el procesamiento de su base se ha producido un error, intente realizar la importación de nuevo. <br />En caso de duda, póngase en contacto con el equipo SoluCX para que le ayude a corregir la base.
    `,
    DISPATCHING_TITLE: 'Base siendo disparada.',
    DISPATCHING_MESSAGE: `
        Tu base de experiencia se está disparando, esta operación puede tardar un poco. <br />Puede acceder a <a href="{extractUrl}">Extracto General</a> y realizar un seguimiento de los envíos que ya se han producido.
    `,
    DISPATCH_QUEUE_TITLE: 'Su base está en la cola de envíos',
    DISPATCH_QUEUE_MESSAGE: `
        Su base de experiencia está actualmente en la cola para ser enviada, por favor espere unos minutos. <br />En unos instantes se desencadenarán sus experiencias.
    `,
    VALIDATING_BASE_TITLE: 'Su base está siendo validada',
    VALIDATING_BASE_MESSAGE: 'Espere unos minutos mientras se procesa la información de su base.',
    IMPORT_AND_VALIDATE_BASE_BUTTON: 'Importar y validar la base',
    INFORMATION_SUMMARY: 'Resumen de la información',
    DATE_LABEL: 'Fechas',
    STORE_LABEL: 'Tiendas',
    JOURNEY_LABEL: 'Trayectos',
    TOTAL_RECORDS: 'registros totales',
    INCLUDED_IN_THE_BASE: 'incluido en la base',
    DATE_VALIDATION_ERROR_TITLE: 'Formato de fecha incorrecto en la base',
    DATE_VALIDATION_ERROR_MESSAGE:
        'Se han encontrado fechas con un formato no válido en su base de datos. <br />Actualice la información de la fecha al formato AAAA-MM-DD o AAAA-MM-DD HH:mm:ss y vuelva a realizar la importación.',
    HEADER_VALIDATION_ERROR_TITLE: '¡Error en la identificación de la columna!',
    HEADER_VALIDATION_ERROR_MESSAGE:
        'Durante la validación de su base no fue posible identificar las columnas. Asegúrese de que los nombres de las columnas siguen el patrón indicado. <br />En caso de duda, póngase en contacto con el equipo SoluCX para que le ayude a corregir la base.',
    AMOUNT: 'Cantidad',
    DESCRIPTION: 'Descripción',
    CLASSIFICATION: 'Clasificación',
    ACTION: 'Acción',
    SELECT_ACTION: 'Seleccione una acción',
    SELECT_ACTION_FOR_THE_ERROR: 'Seleccione una acción para cada error abajo',
    ERROR: 'Error',
    WARNING: 'Alerta',
    REMOVE_LINE: 'Eliminar línea',
    IGNORE_LINE: 'Saltar línea',
    REMOVE_INFO: 'Eliminar información',
    FIX_INFO: 'Fijar información',
    AMOUNT_OF_ERRORS_FOUND: 'Número total de errores encontrados',
    IS_TRANSACTION_EXTERNAL_ID_WELL_FORMATTED: 'Identificación de transacción no válida',
    IS_TRANSACTION_EXTERNAL_ID_NOT_REPEATED: 'Identificación de transacción duplicada',
    IS_STORE_EXISTENT: 'ID de tienda no registrado',
    IS_STORE_NOT_EMPTY: 'Tienda no válida - vacía',
    IS_JOURNEY_EXISTENT: 'Viaje no identificado',
    IS_CUSTOMER_EXTERNAL_ID_NOT_REPEATED: 'Identificación de cliente duplicada',
    IS_EXPERIENCE_DATE_WELL_FORMATTED: 'Formato de fecha no válido',
    IS_EXPERIENCE_DATE_NOT_FROM_FUTURE: 'Fecha futura no válida',
    IS_EMAIL_NOT_REPEATED: 'Correo electrónico duplicado en la base',
    IS_EMAIL_WELL_FORMATTED: 'Correo electrónico no válido - ortografía incorrecta',
    IS_EMAIL_NOT_BLACKLISTED: 'Correo electrónico no válido - en la lista negra',
    IS_EMAIL_DOMAIN_NOT_MISTAKEN: 'Correo electrónico no válido - mal escrito',
    IS_EMAIL_NOT_SUSPECTED: 'Correos electrónicos sospechosos',
    IS_EMAIL_NOT_EMPTY: 'Correo electrónico no válido - vacío',
    IS_EMAIL_GMAIL_COMPLIANT: 'Correo electrónico no válido - reglas de Gmail',
    IS_EMAIL_GENERAL_DOMAIN_COMPLIANT: 'Correo electrónico no válido - reglas generales',
    IS_MAIN_PHONE_WELL_FORMATTED: 'Formato de teléfono no válido - no estándar',
    IS_MAIN_PHONE_VALID_BRAZILIAN_DDD: 'Número de teléfono no válido - código de área desconocido',
    IS_ADDITIONAL_PHONE_WELL_FORMATTED: 'Formato de teléfono adicional no válido - no estándar',
    IS_ADDITIONAL_PHONE_VALID_BRAZILIAN_DDD: 'Número de teléfono adicional no válido - código de área desconocido',
    IS_DOCUMENT_LENGTH_VALID: 'Límite de caracteres',
    IS_CUSTOMER_NAME_NOT_EMPTY: 'Nombre de cliente vacío',
    IS_STORE_ACTIVE: 'Tiendas deshabilitadas',
    ADJUST_AND_DISPATCH_BASE: 'Ajustar y enviar',
    ERROR_CALLING_CLEAN_BASE_TITLE: 'Error inesperado',
    ERROR_CALLING_CLEAN_BASE_MESSAGE: 'Se ha producido un error al intentar borrar la base. Vuelva a intentarlo más tarde.',
    ERROR_ACTION_IS_EMPTY_TITLE: 'Seleccione una acción para todos los elementos enumerados en la tabla.',
    ERROR_ACTION_IS_EMPTY_MESSAGE: '¡Todavía hay errores sin corregir!',
    APPLYING_ADJUSTS_TITLE: 'Se están aplicando ajustes.',
    APPLYING_ADJUSTS_MESSAGE: 'Las acciones seleccionadas se están aplicando a su base de datos. Por favor, espere unos minutos.',
    NO_RESULTS: 'No se han encontrado errores en su base',
    ERROR_CALLING_DELETE_BASE_TITLE: 'Error al intentar retirar la base',
    ERROR_CALLING_DELETE_BASE_MESSAGE: 'Se ha producido un error al intentar retirar la base. Vuelva a intentarlo más tarde.',
    POPUP_CANCEL_DISPATCH_TITLE: 'Interrumpir envío',
    POPUP_CANCEL_DISPATCH_MESSAGE: '¿Está seguro de que desea cancelar el envío? Al confirmar esta acción, se descartarán todos los progresos realizados.',
    YES_CANCEL_THE_DISPATCH: 'Si, cancelar el envio',
    POPUP_DISPATCH_TITLE: 'Envío de base',
    POPUP_DISPATCH_MESSAGE: '¿Está seguro de que desea enviar la base? Al confirmar esta acción, no será posible cancelar el envío.',
    CLOSE: 'Cerrar',
    GO_TO_EXTRACT: 'Ir al extracto',
    DOWNLOAD_ERRORS: 'Descargar errores',
    BASES_TITLE: 'Bases enviadas',
    NO_IMPORTED_BASES: 'Hubo un error al recuperar las bases de datos.',
    ERROR_RETRIEVING_BASES: 'No se importaron bases de datos.',
    RECORDS: 'Registros',
    DISPATCHES: 'Envios',
    NON_DISPATCHES: 'No enviada',
    CREATED: 'Creado',
    UPLOADED: 'Subido',
    VALIDATING: 'Validando',
    VALIDATION_ERROR: 'Error de validación',
    DATE_VALIDATION_ERROR: 'Error de fecha',
    EMPTY_HEADER_FIELD_ERROR: 'Cabecera vacía',
    TRAILING_DATA_ERROR: 'Datos retrospectivos',
    MISSING_REQUIRED_FIELDS_ERROR: 'Faltan campos obligatorios',
    VALIDATED: 'Validado',
    ADJUSTING_QUEUE: 'Ajustando cola',
    ADJUSTING_BASE: 'Ajustando base',
    ADJUSTED_BASE: 'Base ajustada',
    DISPATCHING: 'Enviando',
    DISPATCHED_ALL: 'Enviado',
    DISPATCHED_INCOMPLETE: 'Enviado',
    DISPATCH_ERROR: 'Error de envio',
    UNKNOWN: 'Desconocido',
    BASE_NAME: 'Nombre de la base',
    STATUS: 'Estado',
    DISPATCH_START_AT: 'Inicio de lo envio',
    DISPATCH_ENDED_AT: 'Fin de lo envio',
    DOWNLOADING_FILE_ERROR: 'Se ha producido un error al intentar descargar el archivo',
    SELECT_A_BASE: 'Seleccione una base importada para ver su información.',
    THERE_WAS_ERROR_RETRIEVING_BASE_DATA: 'Se ha producido un error al recuperar los datos de los registros de la base',
    THERE_WAS_ERROR_RETRIEVING_NON_DISPATCH_REASONS: 'Se ha producido un error al recuperar los motivos de no envío de la base',
    EMAILSERVICE_INVALID_EMAIL: 'Configuración de servicio de correo electrónico no válida',
    EMAILSERVICE_BOUNCED_EMAIL: 'El servicio de correo electrónico rechazó el mensaje',
    CALCULATOR_NO_DAILY_BUDGET: 'Unidad sin presupuesto diario para el envío',
    CALCULATOR_DAILY_BUDGET_EXCEEDED: 'Se superó el presupuesto diario de la unidad',
    SURVEY_BLOCKED: 'Lista de exclusión',
    CUSTOMER_EMAIL_BLACKLIST: 'Dominio en la lista negra',
    DOUBLE_IN_DAY_DISPATCH: 'Contacto ya enviado en el día',
    CUSTOMER_INVALID_EMAIL: 'Correo electrónico no válido',
    CUSTOMER_INVALID_PHONE: 'Número de teléfono no válido',
    EMAIL_OPTOUT: 'Cancelación de suscripción por correo electrónico',
    SMS_OPTOUT: 'Cancelación de suscripción por SMS',
    WHATSAPP_OPTOUT: 'Cancelación de suscripción por WhatsApp',
    URA_OPTOUT: 'Cancelación de suscripción por llamada telefónica',
    QUARANTINE_TRANSACTION: 'Cuarentena de envío',
    QUARANTINE_RATING: 'Cuarentena de evaluación',
    STORE_CANNOT_SEND_EMAIL: 'Unidad bloqueada para el envío',
    INTERNAL_ERROR: 'Error temporal',
    CONFIGURATION_ERROR: 'Error en la configuración de la trayecto',
    EMPTY_HEADER_FIELD_ERROR_TITLE: 'Base con cabecera vacía',
    EMPTY_HEADER_FIELD_ERROR_MESSAGE: 'Algunas columnas de su base tienen cabeceras vacías. <br />Corrige la cabecera de tu base e intenta importar de nuevo.',
    TRAILING_DATA_ERROR_TITLE: 'Datos finales en la base',
    TRAILING_DATA_ERROR_MESSAGE:
        'Hay información en su base que no cabe en una columna especificada en la cabecera. <br />Elimine la información o añada una cabecera e intente de nuevo la importación.',
    MISSING_REQUIRED_FIELDS_ERROR_TITLE: 'Base con cabecera incompleta',
    MISSING_REQUIRED_FIELDS_ERROR_MESSAGE:
        'Tu cabecera base no tiene todos los campos requeridos. <br />Corrija su base de datos añadiendo todos los campos obligatorios e intente importar de nuevo.',
    API_CUSTOMER_INVALID_NAME: 'El nombre del cliente no puede estar vacío',
    API_CUSTOMER_PHONE_LESS_THAN_10_DIGITS: 'El teléfono tiene menos de 10 dígitos',
    API_CUSTOMER_ADDITIONAL_PHONE_LESS_THAN_10_DIGITS: 'El teléfono adicional tiene menos de 10 dígitos',
    API_CUSTOMER_INVALID_CPF: 'CPF no válido',
    API_CUSTOMER_INVALID_EMAIL: 'Correo electrónico no válido',
    API_EMPLOYEE_INVALID_NAME: 'El nombre del empleado no debe estar vacío',
    API_STORE_INVALID_NAME: 'El nombre de la tienda no puede estar vacío',
    API_TRANSACTION_INVALID_AMOUNT: 'El valor de la transacción debe ser numérico.',
    TRANSACTION_ALREADY_CREATED: 'Transacción ya creada',
    IS_MAIN_PHONE_NOT_VALID_DOCUMENT: 'El número de teléfono también es un CPF válido',
    IS_ADDITIONAL_PHONE_NOT_VALID_DOCUMENT: 'El número de teléfono adicional también es un CPF válido',
    JOURNEY_FOUND_BUT_NOT_ACTIVE: 'Trayecto no activo',
    IS_JOURNEY_ACTIVE: 'Trayecto no activo',
    IS_DOCUMENT_CONTENT_VALID: 'Documento no válido',
};

export default es;
