import InstanceData from '@/app/Instance/Models/InstanceData';

export default class Instance {
    public readonly slug: string;
    public readonly token: string;
    public readonly mainColor: string;

    public constructor(instanceData: InstanceData) {
        this.slug = instanceData.slug;
        this.token = instanceData.token;
        this.mainColor = instanceData.mainColor;
    }
}
