export enum FileStatus {
    STANDBY = 'STANDBY',
    VALIDATING = 'VALIDATING',
    NO_FILE = 'NO_FILE',
    INVALID_EXTENSION_ERROR = 'INVALID_EXTENSION_ERROR',
    INVALID_CSV_HEADER = 'INVALID_CSV_HEADER',
    FILESIZE_LIMIT = 'FILESIZE_LIMIT',
    VALIDATION_ERROR = 'VALIDATION_EROR',
    GENERAL_ERROR = 'GENERAL_ERROR',
    VALIDATION_SUCCESSFULLY = 'VALIDATION_SUCCESSFULLY',
    UPLOADING = 'UPLOADING',
    UPLOADED = 'UPLOADED',
}
