import { BaseStatus } from '@/app/BaseImporter/Models/Base/BaseStatus';
import DispatchedBaseDto from '@/app/Bases/Models/DispatchedBaseDto';

export default class DispatchedBase implements DispatchedBaseDto {
    public id: string;
    public filename: string;
    public status: BaseStatus;
    public startedAt: string;
    public endedAt: string;
    public totalRecords: number;
    public dispatches: number;
    public nonDispatches: number;

    constructor(dispatchedBase: DispatchedBaseDto) {
        this.id = dispatchedBase.id;
        this.filename = dispatchedBase.filename;
        this.status = dispatchedBase.status;
        this.startedAt = dispatchedBase.startedAt;
        this.endedAt = dispatchedBase.endedAt;
        this.totalRecords = dispatchedBase.totalRecords;
        this.dispatches = dispatchedBase.dispatches;
        this.nonDispatches = dispatchedBase.nonDispatches;
    }
}
