import DispatchedBase from '@/app/Bases/Models/DispatchedBase';
import DispatchedBaseDto from '@/app/Bases/Models/DispatchedBaseDto';
import NonDispatchBaseResult from '@/app/Bases/Models/NonDispatchBaseResult';
import NonDispatchBaseResultDto from '@/app/Bases/Models/NonDispatchBaseResultDto';
import ExportUrl from '@/app/Common/Models/ExportUrl';
import PaginationQuery from '@/app/Common/Models/PaginationQuery';
import BaseService from '@/app/Common/Services/BaseService';
import Base from '../Models/Base/Base';
import BaseDto from '../Models/Base/BaseDto';
import UploadSummary from '../Models/Base/UploadSummary';
import UploadSummaryDto from '../Models/Base/UploadSummaryDto';
import { ValidationActions } from '../Models/BaseAdjustment/ValidationActions';
import ValidationResult from '../Models/BaseAdjustment/ValidationResult';
import ValidationResultDto from '../Models/BaseAdjustment/ValidationResultDto';
import { ValidationRules } from '../Models/BaseAdjustment/ValidationRules';
import { DetailedEntities } from '../Models/Entity/DetailedEntities';
import EntityDetails from '../Models/Entity/EntityDetails';
import EntityDetailsDto from '../Models/Entity/EntityDetailsDto';

export default class BaseManagementService extends BaseService {
    public async createBase(): Promise<Base> {
        const base = await this._post<BaseDto>('/bases');
        return new Base(base);
    }

    public async getBases(): Promise<Base[]> {
        const bases = await this._get<BaseDto[]>('/bases');
        return bases.map(base => new Base(base));
    }

    public async getDispatchedBaseSummary(baseId: string): Promise<DispatchedBase> {
        const base = await this._get<DispatchedBaseDto>(`/bases/${baseId}/import/summary`);
        return new DispatchedBase(base);
    }

    public async refreshBase(baseId: string): Promise<Base> {
        const base = await this._post<BaseDto>(`/bases/${baseId}/refresh`);
        return new Base(base);
    }

    public async getEntitiesResume(baseId: string): Promise<UploadSummary> {
        const summary = await this._get<UploadSummaryDto>(`/bases/${baseId}/validation/summary`);
        return new UploadSummary(summary);
    }

    public async getEntityDetails(baseId: string, entity: DetailedEntities, query: PaginationQuery = { limit: 20, skip: 0 }): Promise<EntityDetails> {
        const details = await this._get<EntityDetailsDto>(`/bases/${baseId}/validation/summary/${entity}`, query);
        return new EntityDetails(details);
    }

    public async getValidationResults(baseId: string): Promise<ValidationResult[]> {
        const results = await this._get<ValidationResultDto[]>(`/bases/${baseId}/validation/result`);
        return results.map(result => new ValidationResult(result));
    }

    public async cleanBase(baseId: string, actionsMap: Partial<Record<ValidationRules, ValidationActions>>): Promise<void> {
        await this._post(`/cleaner/${baseId}/enqueue`, actionsMap);
    }

    public async deleteBase(baseId: string): Promise<void> {
        await this._del(`/bases/${baseId}`);
    }

    public async downloadErrors(baseId: string): Promise<ExportUrl> {
        const result = await this._get<ExportUrl>(`/bases/${baseId}/validation/result/export`);
        return result;
    }

    public async getNonDispatchBaseResults(baseId: string): Promise<NonDispatchBaseResult[]> {
        const results = await this._get<NonDispatchBaseResultDto[]>(`/bases/${baseId}/import/errors`);
        if (!results) return [];
        return results?.map(result => new NonDispatchBaseResult(result));
    }

    public async downloadNonDispatchBaseResults(baseId: string): Promise<Blob> {
        const result = await this._get<Blob>(`/bases/${baseId}/import/errors/export`, null, { responseType: 'blob' });
        return result;
    }
}
