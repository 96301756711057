import { BaseStatus } from './BaseStatus';

export default class BaseState {
    public status: BaseStatus = null;

    constructor(status: BaseStatus) {
        this.status = status.toLowerCase() as BaseStatus;
    }

    public get isDispatched(): boolean {
        return this.status === BaseStatus.DISPATCHED_ALL || this.status === BaseStatus.DISPATCHED_INCOMPLETE;
    }

    public get hasDispatchError(): boolean {
        return (
            this.status === BaseStatus.DISPATCH_ERROR ||
            this.status === BaseStatus.MISSING_REQUIRED_FIELDS_ERROR ||
            this.status === BaseStatus.DATE_VALIDATION_ERROR ||
            this.status === BaseStatus.VALIDATION_ERROR ||
            this.status === BaseStatus.EMPTY_HEADER_FIELD_ERROR ||
            this.status === BaseStatus.TRAILING_DATA_ERROR
        );
    }
}
