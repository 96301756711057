import EntityDetailsDto from './EntityDetailsDto';

export default class EntityDetails implements EntityDetailsDto {
    public readonly count: number;
    public readonly data: string[];

    constructor(details: EntityDetailsDto) {
        this.count = details.count;
        this.data = details.data;
    }
}
