import { getCookie } from '@/app/Common/Services/CookieService';
import Instance from '../Models/Instance';

export default class InstanceService {
    public getInstanceFromCookie(): Instance {
        const slug = this.getInstanceSlugFromUrl();
        if (!slug) return null;

        const instanceCookieData: string = getCookie(`solucx_instance_${slug}`);
        const decodedInstanceData: string = decodeURIComponent(instanceCookieData);
        return new Instance(JSON.parse(decodedInstanceData));
    }

    private getInstanceSlugFromUrl(): string {
        const urlParameters = new URLSearchParams(window.location.search);
        const slug = urlParameters.get('slug');
        return slug ?? null;
    }
}
