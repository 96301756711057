import FileUploaderService from '@/app/BaseImporter/Services/FileUploaderService';
import axios, { AxiosInstance } from 'axios';
import BaseService from './BaseService';
import BaseManagementService from '@/app/BaseImporter/Services/BaseManagementService';

export default class ServicesInitializer {
    private readonly client: AxiosInstance = null;

    constructor() {
        this.client = this.createClient();
    }

    public getServices(instanceToken: string, userToken: string): Record<string, BaseService> {
        return {
            fileUploader: new FileUploaderService(this.client, instanceToken, userToken),
            baseManagement: new BaseManagementService(this.client, instanceToken, userToken),
        };
    }

    private createClient(): AxiosInstance {
        const baseURL = process.env.VUE_APP_API_BASE_URL || 'https://api.base-uploader.solucx.com.br';
        return axios.create({ baseURL });
    }
}
