import Language from './Language';

const pt: Language = {
    BASE_UPLOAD: 'Importar base',
    BASE_VALIDATION: 'Validação da base',
    BASE_ADJUSTMENT: 'Ajuste da base',
    BASE_SENT: 'Base enviada',
    BASE_IMPORTER_TITLE: 'Importador de base',
    UPLOAD_BASE_BLOCK_SUBTITLE:
        'Para realizar o upload da sua base, primeiro é necessário verificar se o arquivo está pronto para ser enviado. Siga as instruções abaixo para ter certeza que sua base segue todas as regras necessárias para o upload ser concluído.',
    INSTRUCTIONS_BLOCK_TITLE: 'Estrutura e padrão da base',
    INSTRUCTIONS_BLOCK_SUBTITLE: 'Para realizar a importação de suas experiências é necessário concluir as seguintes condições:',
    IMPORT_INSTRUCTIONS: `
        <li class="text-muted">O arquivo deve ser no formato CSV.</li>
        <li class="text-muted">Deve possuir o tamanho máximo de 30 megabytes.</li>
        <li class="text-muted">O título das colunas deve seguir exatamente o padrão do template.</li>
        <li class="text-muted">Linhas que possuem informações obrigatórias em branco não serão registradas.</li>
        <li class="text-muted">As colunas <strong>id_cliente</strong>, <strong>nome_do_cliente</strong>, <strong>email</strong> ou <strong>telefone</strong>, <strong>id_transacao</strong>, <strong>id_loja</strong>, <strong>data_transacao</strong> e <strong>jornada</strong> são consideradas obrigatórias, portanto precisam estar preenchidas.</li>
        <li class="text-muted">É obrigatório ter a informação de contato referente ao canal de disparo configurado. Por exemplo, caso a jornada esteja configurada para disparar um e-mail, é obrigatório ter este dado na base.</li>
        <li class="text-muted">Preencher as colunas não obrigatórias garante uma boa experiência utilizando a plataforma.</li>
        <li class="text-muted">Números de telefone necessitam seguir o formato DDD + Número. Exemplo: (12) 999999999.</li>
        <li class="text-muted">O <strong>id_cliente</strong> é o código que será utilizado para encontrar o cliente na plataforma, sendo possível adicionar <strong>cpf</strong>, <strong>email</strong> ou qualquer outro dado que auxilie na busca.</li>
        <li class="text-muted">As informações devem ser separadas em 3 categorias: <strong>obrigatórias</strong>, <strong>importantes</strong> e <strong>parâmetros extras</strong>.</li>
        <ul class="text-muted m-0">
            <li>As informações obrigatórias são: <strong>id_cliente</strong>, <strong>nome_do_cliente</strong>, <strong>email</strong> ou <strong>telefone</strong>, <strong>id_transacao</strong>, <strong>id_loja</strong>, <strong>data_transacao</strong> e <strong>jornada</strong>.</li>
            <li>As informações importantes são: <strong>id_consultor</strong>, <strong>sexo_cliente</strong>, <strong>telefone</strong>, <strong>cpf</strong>, <strong>valor</strong>, <strong>nome_do_vendedor</strong>.</li>
            <li>As demais colunas serão consideradas como parâmetros extras.</li>
        </ul>
        <li class="text-muted">Caso a estrutura seja seguida corretamente, sua base irá passar pelo processo de validação. Porém, isso não garante que todas as experiências que a compõem serão disparadas com sucesso.</li>
    `,
    DOWNLOAD_TEMPLATE_BUTTON: 'Baixar template da base',
    INVALID_EXTENSION_ERROR: 'Formato de arquivo invalido',
    FILESIZE_LIMIT: 'Limite de tamanho do arquivo excedido',
    NO_FILE: 'Não foi possível ler o arquivo, tente novamente',
    UPLOAD_SELECTION_INSTRUCTIONS: 'Arraste e solte ou <strong>escolha</strong> um arquivo no formato csv',
    FILESIZE_LIMIT_INSTRUCTION: 'Tamanho máximo de 30MB',
    CANCEL: 'Cancelar',
    LOCALLY_PROGRESS_MESSAGE: 'Fazer upload do arquivo de acordo com o padrão CSV.',
    IMPORT_AND_DISPATCH_BUTTON: 'Importar e disparar',
    FILE_UPLOADING_PROGRESS: 'Validação da base e seus registros...',
    FILE_UPLOADING_ERROR_DETECTED: 'Erro detectado durante a validação da base',
    API_UPLOAD_ERROR_MESSAGE: 'Confira as condições estabelecidas na estrutura padrão da base e tente novamente',
    DISPATCH_BEING_PREPARED_TITLE: 'Base sendo preparada para o disparo.',
    DISPATCH_BEING_PREPARED_MESSAGE: `
        Os ajustes feitos na base serão aplicados e sem seguida a base será direcionada para a fila de disparos. É possível acompanhar as <br /> mudanças de status da sua base por meio da <strong>Central de notificação</strong> e página e Bases disparadas.
    `,
    DISPATCH_ERROR_TITLE: 'Ocorreu um erro no processamento da base!',
    DISPATCH_ERROR_MESSAGE: `Durante o processamento de sua base ocorreu um erro, tente realizar a importação novamente. <br />Em caso de dúvida contate o time da SoluCX para auxiliar na correção da base.
    `,
    DISPATCHING_TITLE: 'Base sendo disparada.',
    DISPATCHING_MESSAGE: `
        Sua base de experiências está sendo disparada, essa operação pode demorar um pouco. <br />Você pode acessar o <a href="{extractUrl}">Extrato Geral</a> e acompanhar os disparos já realizados.
    `,
    DISPATCH_QUEUE_TITLE: 'Sua base está na fila de disparos.',
    DISPATCH_QUEUE_MESSAGE: `
        Sua base de experiências está atualmente na fila para ser disparada, aguarde alguns minutos. <br />
        Em instantes suas experiências serão disparadas.
    `,
    VALIDATING_BASE_TITLE: 'Sua base está sendo validada',
    VALIDATING_BASE_MESSAGE: 'Aguarde alguns minutos enquanto as informações da sua base são processadas.',
    IMPORT_AND_VALIDATE_BASE_BUTTON: 'Importar e validar base',
    INFORMATION_SUMMARY: 'Resumo das informações',
    DATE_LABEL: 'Datas',
    STORE_LABEL: 'Unidades',
    JOURNEY_LABEL: 'Jornadas',
    TOTAL_RECORDS: 'registros totais',
    INCLUDED_IN_THE_BASE: 'inclusas na base',
    DATE_VALIDATION_ERROR_TITLE: 'Formatação incorreta de data na base',
    DATE_VALIDATION_ERROR_MESSAGE:
        'Foram encontradas datas com formatação inválida em sua base. <br />Atualize as informações de data para o formato YYY-MM-DD ou YYYY-MM-DD HH:mm:ss e realize a importação novamente.',
    HEADER_VALIDATION_ERROR_TITLE: 'Erro durante a identificação das colunas!',
    HEADER_VALIDATION_ERROR_MESSAGE:
        'Durante o processamento da sua base não foi possível identificar as colunas. Verifique se os nomes das colunas seguem o padrão indicado. <br />Em caso de dúvida contate o time da SoluCX para auxiliar na correção da base.',
    AMOUNT: 'Quantidade',
    DESCRIPTION: 'Descrição',
    CLASSIFICATION: 'Classificação',
    ACTION: 'Ação',
    SELECT_ACTION: 'Selecione uma ação',
    SELECT_ACTION_FOR_THE_ERROR: 'Selecione uma ação para cada erro abaixo',
    ERROR: 'Erro',
    WARNING: 'Alerta',
    REMOVE_LINE: 'Remover linha',
    IGNORE_LINE: 'Ignorar linha',
    REMOVE_INFO: 'Remover informação',
    FIX_INFO: 'Corrigir informação',
    AMOUNT_OF_ERRORS_FOUND: 'Quantidade total de erros encontrados',
    IS_TRANSACTION_EXTERNAL_ID_WELL_FORMATTED: 'Id transação inválido',
    IS_TRANSACTION_EXTERNAL_ID_NOT_REPEATED: 'Id transação duplicado',
    IS_STORE_EXISTENT: 'Id loja não cadastrado',
    IS_STORE_NOT_EMPTY: 'Loja inválida - vazia',
    IS_JOURNEY_EXISTENT: 'Jornada não identificada',
    IS_CUSTOMER_EXTERNAL_ID_NOT_REPEATED: 'Id cliente duplicado',
    IS_EXPERIENCE_DATE_WELL_FORMATTED: 'Formato de data inválido',
    IS_EXPERIENCE_DATE_NOT_FROM_FUTURE: 'Data futura inválida',
    IS_EMAIL_NOT_REPEATED: 'E-mail duplicado na base',
    IS_EMAIL_WELL_FORMATTED: 'E-mail inválido - formatação',
    IS_EMAIL_NOT_BLACKLISTED: 'E-mail inválido - blacklist',
    IS_EMAIL_DOMAIN_NOT_MISTAKEN: 'E-mail inválido - digitação errada',
    IS_EMAIL_NOT_SUSPECTED: 'E-mails suspeitos',
    IS_EMAIL_NOT_EMPTY: 'E-mail inválido - vazio',
    IS_EMAIL_GMAIL_COMPLIANT: 'E-mail inválido - regras do gmail',
    IS_EMAIL_GENERAL_DOMAIN_COMPLIANT: 'E-mail inválido - regras gerais',
    IS_MAIN_PHONE_WELL_FORMATTED: 'Formato de telefone inválido - fora do padrão',
    IS_MAIN_PHONE_VALID_BRAZILIAN_DDD: 'Telefone inválido - DDD desconhecido',
    IS_ADDITIONAL_PHONE_WELL_FORMATTED: 'Formato de telefone adicional inválido - fora do padrão',
    IS_ADDITIONAL_PHONE_VALID_BRAZILIAN_DDD: 'Telefone adicional inválido - DDD desconhecido',
    IS_DOCUMENT_LENGTH_VALID: 'Limite de caracteres',
    IS_CUSTOMER_NAME_NOT_EMPTY: 'Nome do cliente vazio',
    IS_STORE_ACTIVE: 'Unidade desativada',
    ADJUST_AND_DISPATCH_BASE: 'Ajustar e disparar',
    ERROR_CALLING_CLEAN_BASE_TITLE: 'Erro inesperado',
    ERROR_CALLING_CLEAN_BASE_MESSAGE: 'Ocorreu um erro ao tentar limpar a base. Tente novamente mais tarde.',
    ERROR_ACTION_IS_EMPTY_TITLE: 'Selecione uma ação para todos os itens listados na tabela.',
    ERROR_ACTION_IS_EMPTY_MESSAGE: 'Ainda existem erros sem correção!',
    APPLYING_ADJUSTS_TITLE: 'Os ajustes estão sendo aplicados',
    APPLYING_ADJUSTS_MESSAGE: 'As ações selecionadas estão sendo aplicadas em sua base. Aguarde alguns minutos.',
    NO_RESULTS: 'Não foi encontrado erros na sua base',
    ERROR_CALLING_DELETE_BASE_TITLE: 'Erro ao tentar remover a base',
    ERROR_CALLING_DELETE_BASE_MESSAGE: 'Ocorreu um erro ao tentar remover a base. Tente novamente mais tarde.',
    POPUP_CANCEL_DISPATCH_TITLE: 'Interromper disparo',
    YES_CANCEL_THE_DISPATCH: 'Sim, cancelar o disparo',
    POPUP_CANCEL_DISPATCH_MESSAGE: 'Tem certeza que deseja cancelar o disparo? Ao confirmar esta ação, todo o progresso feito será descartado.',
    POPUP_DISPATCH_TITLE: 'Disparar base',
    POPUP_DISPATCH_MESSAGE: 'Tem certeza que deseja disparar a base? Ao confirmar esta ação, não será possível cancelar o disparo.',
    CLOSE: 'Fechar',
    GO_TO_EXTRACT: 'Ir até o Extrato',
    DOWNLOAD_ERRORS: 'Baixar erros',
    BASES_TITLE: 'Bases disparadas',
    NO_IMPORTED_BASES: 'Houve um erro ao recuperar as bases',
    ERROR_RETRIEVING_BASES: 'Nenhuma base importada',
    RECORDS: 'Registros',
    DISPATCHES: 'Disparados',
    NON_DISPATCHES: 'Não disparados',
    CREATED: 'Criado',
    UPLOADED: 'Importado',
    VALIDATING: 'Validando',
    VALIDATION_ERROR: 'Erro na validação',
    EMPTY_HEADER_FIELD_ERROR: 'Cabeçalho vazio',
    TRAILING_DATA_ERROR: 'Informações sem cabeçalho',
    MISSING_REQUIRED_FIELDS_ERROR: 'Cabeçalho incompleto',
    DATE_VALIDATION_ERROR: 'Erro na data',
    VALIDATED: 'Validado',
    ADJUSTING_QUEUE: 'Fila de ajustes',
    ADJUSTING_BASE: 'Ajustando base',
    ADJUSTED_BASE: 'Base ajustada',
    DISPATCHING: 'Disparando',
    DISPATCHED_ALL: 'Disparo concluído',
    DISPATCHED_INCOMPLETE: 'Disparo concluído',
    DISPATCH_ERROR: 'Erro no disparo',
    UNKNOWN: 'Desconhecido',
    BASE_NAME: 'Nome da base',
    STATUS: 'Status',
    DISPATCH_START_AT: 'Inicio do disparo',
    DISPATCH_ENDED_AT: 'Fim do disparo',
    DOWNLOADING_FILE_ERROR: 'Houve um erro ao tentar baixar o arquivo',
    SELECT_A_BASE: 'Selecione uma base importada para ver suas informações.',
    THERE_WAS_ERROR_RETRIEVING_BASE_DATA: 'Houve um erro ao recuperar os dados de registro da base',
    THERE_WAS_ERROR_RETRIEVING_NON_DISPATCH_REASONS: 'Houve um erro ao recuperar os motivos de não disparo da base',
    EMAILSERVICE_INVALID_EMAIL: 'Configuração inválida de serviço de e-mail',
    EMAILSERVICE_BOUNCED_EMAIL: 'Serviço de e-mail rejeitou a mensagem',
    CALCULATOR_NO_DAILY_BUDGET: 'Unidade sem verba diária para disparo',
    CALCULATOR_DAILY_BUDGET_EXCEEDED: 'Excedeu a verba diária da unidade',
    SURVEY_BLOCKED: 'Lista de exclusão',
    CUSTOMER_EMAIL_BLACKLIST: 'Domínio na blacklist',
    DOUBLE_IN_DAY_DISPATCH: 'Contato já disparado no dia',
    CUSTOMER_INVALID_EMAIL: 'E-mail inválido',
    CUSTOMER_INVALID_PHONE: 'Telefone inválido',
    EMAIL_OPTOUT: 'Opt-out de e-mail',
    SMS_OPTOUT: 'Opt-out de SMS',
    WHATSAPP_OPTOUT: 'Opt-out de WhtasApp',
    URA_OPTOUT: 'Opt-out de Ligação telefônica',
    QUARANTINE_TRANSACTION: 'Quarentena de disparo',
    QUARANTINE_RATING: 'Quarentena de avalição',
    STORE_CANNOT_SEND_EMAIL: 'Unidade bloqueada para disparo',
    INTERNAL_ERROR: 'Erro temporário',
    CONFIGURATION_ERROR: 'Erro na configuração da jornada',
    EMPTY_HEADER_FIELD_ERROR_TITLE: 'Base com cabeçalho vazio',
    EMPTY_HEADER_FIELD_ERROR_MESSAGE:
        'Alguma colunas da sua base estão com cabeçalho vazio. <br />Corrija o cabeçalho da sua base e tente realizar a importação novamente.',
    TRAILING_DATA_ERROR_TITLE: 'Informações sem cabeçalho na base',
    TRAILING_DATA_ERROR_MESSAGE:
        'Existem informações na sua base que não estão enquadradas em alguma coluna especificada no cabeçalho. <br />Remova as informações ou adiciona cabeçalho e tente realizar a importação novamente.',
    MISSING_REQUIRED_FIELDS_ERROR_TITLE: 'Base com cabeçalho incompleto',
    MISSING_REQUIRED_FIELDS_ERROR_MESSAGE:
        'O cabeçalho da sua base não possui todos os campos obrigatórios. <br />Corrija sua base adicionando todos os campos obrigatórios e tente realizar a importação novamente.',
    API_CUSTOMER_INVALID_NAME: 'Nome do cliente não pode ser vazio',
    API_CUSTOMER_PHONE_LESS_THAN_10_DIGITS: 'Telefone possui menos que 10 dígitos',
    API_CUSTOMER_ADDITIONAL_PHONE_LESS_THAN_10_DIGITS: 'Telefone adicional possui menos que 10 dígitos',
    API_CUSTOMER_INVALID_CPF: 'CPF inválido',
    API_CUSTOMER_INVALID_EMAIL: 'E-mail inválido',
    API_EMPLOYEE_INVALID_NAME: 'Nome do vendedor não pode ser vazio',
    API_STORE_INVALID_NAME: 'Nome da loja não pode ser vazio',
    API_TRANSACTION_INVALID_AMOUNT: 'Valor da transação deve ser numérico',
    TRANSACTION_ALREADY_CREATED: 'Transação já criada',
    IS_MAIN_PHONE_NOT_VALID_DOCUMENT: 'Número de telefone também é um CPF válido',
    IS_ADDITIONAL_PHONE_NOT_VALID_DOCUMENT: 'Telefone adicional também é um CPF válido',
    JOURNEY_FOUND_BUT_NOT_ACTIVE: 'Jornada não ativa',
    IS_JOURNEY_ACTIVE: 'Jornada não ativa',
    IS_DOCUMENT_CONTENT_VALID: 'Documento inválido',
};

export default pt;
