import UploadSummaryDto from './UploadSummaryDto';

export default class UploadSummary implements UploadSummaryDto {
    public readonly journeys: number;
    public readonly dates: number;
    public readonly stores: number;
    public readonly totalRecords: number;

    constructor(summary: UploadSummaryDto) {
        this.journeys = summary.journeys;
        this.dates = summary.dates;
        this.stores = summary.stores;
        this.totalRecords = summary.totalRecords;
    }
}
