import BaseService from '@/app/Common/Services/BaseService';
import axios, { AxiosError, AxiosRequestConfig, CanceledError } from 'axios';
import { ApiError } from '@/app/Common/Models/ApiError';
import { FileStatus } from '@/app/BaseImporter/Models/FileValidation/FileStatus';
import { UploadError } from '../Models/UploadError';

export default class FileUploaderService extends BaseService {
    public uploadPercentage: number = 0;
    public error: UploadError = null;
    private uploaderRequest = this.createRequestToken();

    public async uploadFile(file: File, id: string): Promise<void> {
        this.error = null;

        try {
            this.uploadPercentage = 0;
            const url = `/uploader/${id}`;
            const formData = this.getFormData(file);
            const config: AxiosRequestConfig<FormData> = this.createStreamConfig();
            await this.client.post(url, formData, config);
        } catch (err) {
            this.uploadPercentage = 0;

            if (err instanceof CanceledError) {
                console.warn('Upload cancelled');
                return;
            }
            if (axios.isAxiosError(err)) {
                const { error } = (err as AxiosError<ApiError>).response.data;
                const key = error.toUpperCase() as UploadError;
                this.error = FileStatus[key] ?? FileStatus.GENERAL_ERROR;
                return;
            }
            this.error = FileStatus.GENERAL_ERROR;
        }
    }

    public hasError(): boolean {
        return !!this.error;
    }

    public cancelUpload(): void {
        this.uploaderRequest.cancel('REQUEST_CANCELLED_BY_USER');
        this.uploaderRequest = this.createRequestToken();
        this.uploadPercentage = 0;
    }

    private createStreamConfig(): AxiosRequestConfig<FormData> {
        const headers = { 'Content-Type': 'multipart/form-data' };
        return {
            cancelToken: this.uploaderRequest.token,
            headers,
            onUploadProgress: (progressEvent: ProgressEvent) => {
                const { loaded, total } = progressEvent;
                this.uploadPercentage = Math.floor((loaded * 100) / total);
            },
        };
    }

    private getFormData(file: File): FormData {
        const formData = new FormData();
        formData.append('file', file);
        return formData;
    }
}
