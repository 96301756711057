export default class CustomStyler {
    private style: HTMLLinkElement = null;
    public isLoading: boolean = true;
    public _mounted: boolean = false;

    constructor() {
        this.style = document.createElement('link');
    }

    public mountStyle(color: string): void {
        try {
            this.setStyleLoadWatcher();
            this.setCustomStyleUrl(color);
            this.loadStyle();
        } catch (error) {
            console.error(`[ERROR] Error while setting the custom style: {${error}}`);
        } finally {
            this._mounted = true;
        }
    }

    private setStyleLoadWatcher(): void {
        this.style.onload = () => {
            this.isLoading = false;
        };
    }

    private setCustomStyleUrl(color: string): void {
        this.style.setAttribute('href', `https://bootstrap.solucx.com.br/?color=${encodeURIComponent(color)}`);
        this.style.setAttribute('rel', 'stylesheet');
    }

    private loadStyle(): void {
        document.head.appendChild(this.style);
    }
}
