import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/upload',
        name: 'base-importer',
        component: () => import(/* webpackChunkName: "base-importer" */ '@/app/BaseImporter/BaseImporter.vue'),
    },
    {
        path: '/bases/:baseId?',
        name: 'bases',
        component: () => import(/* webpackChunkName: "bases" */ '@/app/Bases/Bases.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
