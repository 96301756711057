export function getCookie(cookieName: string): string {
    const name = cookieName + '=';
    const cookies: string[] = document.cookie.split(';');

    for (let cookie of cookies) {
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
}
